<template>
  <b-overlay :show="loading" rounded="sm">
    <b-card title="Dinero en repartidores">
      <div class="row mb-1">
        <div class="col display flex">
          <date-picker
            range-separator="-"
            v-model="rangePicker"
            :default-value="rangePicker"
            range
            append-to-body
            lang="es"
            format="YYYY-MM-DD"
            style="width: 200px"
            class="mr-1"
          ></date-picker>
          <b-button
            variant="success"
            class="btn-icon mr-1"
            @click="getReportMoneyInDistributor(false)"
          >
            <feather-icon icon="RefreshCwIcon" size="16"/>
          </b-button>
          <b-button
            variant="gradient-primary"
            class="btn-icon mr-1"
            @click="getReportMoneyInDistributor(true)"
          >
            Ver dinero recibido
          </b-button>
        </div>
      </div>

      <b>
        * El reporte se lista en base a las ordenes que se encuentren en el intervalo de fechas seleccionado
        (por defecto el dia actual)
      </b>

      <hr>

      <b-table
        :items="report"
        :fields="tableColumns"
        striped
        responsive
      >
        <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="custom-control-primary"
            @change="row.toggleDetails"
          >
            <span class="vs-label">{{ row.detailsShowing ? 'Ocultar' : 'Detalle' }}</span>
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card :title="`Dinero pendiente de recibir de ${row.item.distributor.full_name} por orden`">
            <vue-perfect-scrollbar
              ref="refChatLogPS"
              :settings="perfectScrollbarSettings"
              class="user-chats scroll-area"
              v-if="row.item.orders.length"
            >
              <b-card
                :style="`height: ${row.item.orders.length > 0 ? '350px' : '0'}`"
              >
                <b-list-group flush>
                  <b-list-group-item v-for="(order, i) of row.item.orders" :key="i">
                    <div class="d-flex">
                      <router-link :to="{ name: 'order-detail', params: { id: order.id } }">
                        Orden # {{ order.code }}
                      </router-link>
                      <b class="ml-5 mr-5">
                        Dinero entregado al repartidor: {{ currencyFormat(order.receive_amount_distributor) }}<br>
                        <small class="text-danger">
                          Uso de caja chica {{ currencyFormat(order.expense_petty_cash) }}
                        </small>
                      </b>

                      <p v-if="order.distributor_payment_type == 'services'">En servicios</p>
                      <p v-if="order.distributor_payment_type == 'all'">Todo</p>
                      <p v-if="order.distributor_payment_type == 'addons'">Compras</p>

                      <!--
                      <div class="ml-5">
                        <b-form-checkbox
                          v-model="order.receive_distributor_money"
                          class="custom-control-primary"
                          :disabled="order.receive_distributor_money || order.receive_amount_distributor == 0"
                          @change="markReceivedMoney(order, row.item.distributor.id, i)"
                        >
                          Marcar como recibido
                        </b-form-checkbox>
                      </div> -->
                    </div>
                    <div v-if="order.additional_features.length" style="margin-left: 30px">
                      <hr>
                      <h5 class="mb-2">Detalle de compras con caja chica:</h5>
                      <div class="d-flex" v-for="addon of order.additional_features" :key="addon.id">
                        <p class="mr-5"><b>Compra:</b> {{ addon.name }}</p>
                        <p class="mr-5"><b>Costo:</b>  {{ currencyFormat(addon.price) }}</p>
                        <p class="mr-5"><b>Depositado:</b> {{ currencyFormat(addon.deposit) }}</p>
                        <p class="mr-5" v-if="addon.deposit > addon.price">
                          <b>Le sobro del deposito:</b> {{ currencyFormat(addon.deposit - addon.price) }}
                        </p>
                        <p class="mr-5">
                          <b>Debio gastar de caja chica:</b> {{ currencyFormat(addon.expense_petty_cash) }} <br>
                          <small class="text-success">Cargaba en ese momento: {{ currencyFormat(addon.distributor_money_now) }}</small>
                        </p>
                        <p><b>Fecha de la compra:</b> {{ dateFormat(addon.created_at, 'DD/MM/YYYY H:m:s') }}</p>
                      </div>
                    </div>

                  </b-list-group-item>
                </b-list-group>
                <b-button
                  class="mt-1"
                  :disabled="disabledButtonMarkAll(row.item.orders) == undefined"
                  size="sm"
                  variant="gradient-primary"
                  @click="markAllReceived(row.item.orders, row.item.distributor)"
                >
                  Recibir todo
                </b-button>
              </b-card>

            </vue-perfect-scrollbar>

            <h3 v-else>
              Sin dinero pendiente
            </h3>
            <hr>
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Ocultar
            </b-button>
          </b-card>
        </template>

        <template #cell(distributor)="data">
          {{ data.item.distributor.full_name }} <br>
          <b-badge
            :variant="`${data.item.distributor.active ? 'success' : 'danger'}`"
            class="badge-glow"
          >
            {{ data.item.distributor.active ? 'Activo' : 'Inactivo' }}
          </b-badge>
        </template>

        <template #cell(total_money)="data">
          <b class="text-success text-right">
            Monto a entregar: {{ currencyFormat(data.item.total_money) }}
          </b> <br>
          <b class="text-danger text-right">
            Gasto de caja chica: {{ currencyFormat(data.item.total_expense_petty_cash) }}
          </b>
        </template>

        <template #cell(orders)="data">
          <b>{{ data.item.orders.length }} {{ data.item.orders.length > 1 ? 'Ordenes' : 'Orden' }}</b>
        </template>
      </b-table>

      <p class="text-center" v-if="!report.length">
        No hay registros en este rango de fechas
      </p>

    </b-card>
  </b-overlay>
</template>

<script>
import ReportService from '@/services/report.service'
import DatePicker from 'vue2-datepicker';
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import {
  currencyFormat,
  confirmAlert
} from '@/helpers'

import {
  BOverlay,
  BCard,
  BButton,
  BTable,
  BBadge,
  BFormCheckbox,
  BListGroup,
  BListGroupItem
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import moment from "moment";
moment.locale("es");

export default {
  components: {
    VuePerfectScrollbar,
    BOverlay,
    BCard,
    DatePicker,
    BButton,
    BTable,
    BBadge,
    BFormCheckbox,
    BListGroup,
    BListGroupItem
  },
  data() {
    return {
      received_all: false,
      perfectScrollbarSettings: {
        maxScrollbarLength: 10,
      },
      rangePicker: [
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
				new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
      ],
      report: [],
      loading: false,
      tableColumns: [
        { key: 'show_details', label: 'Ver detalle' },
        { key: 'distributor', label: 'Repartidor' },
        { key: 'orders', label: 'Cantidad de ordenes' },
        { key: 'total_money', label: 'Total de dinero' },
      ]
    }
  },
  watch: {
    rangePicker() {
      if (this.rangePicker && this.rangePicker.length > 1) {
        this.getReportMoneyInDistributor()
      }
    }
  },
  methods: {
    disabledButtonMarkAll(orders) {
      const res = orders.find(i => i.receive_distributor_money == 0)
      return res
    },
    alertConfirm(title) {
      return confirmAlert(title)
    },
    async markAllReceived(orders, distributor) {
      const confirmAlert = await this.alertConfirm(
        `Confirmar recibir todo el dinero de ${distributor.full_name}`
      )

      if (!confirmAlert.isConfirmed) return false
      this.loading = true
      const orders_received = orders.filter(i => i.receive_distributor_money == 0).map(i => {
        return i.id
      })
      try {
          const { data:res } = await ReportService.markAllReceivedMoney({
            orders: orders_received
          })
          if (res.success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.getReportMoneyInDistributor()
          } else {
            data.receive_distributor_money = false
            Swal.fire({
              title: res.message,
              text: '',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
          this.loading = false
      } catch (e) {
        Swal.fire({
          title: 'Ha ocurrido un error interno',
          text: '',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.loading = false
      }
    },
    async markReceivedMoney(data, row_id, order_index) {
      if (data.receive_distributor_money) {

        try {
          const { data:res } = await ReportService.markReceivedMoney(
            {
              received: data.receive_distributor_money,
              order_id: data.id
            }
          )
          if (res.success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            const index = this.report.findIndex(i => i.distributor.id == row_id)
            this.report[index].orders.splice(order_index, 1)
          } else {
            data.receive_distributor_money = false
            Swal.fire({
              title: res.message,
              text: '',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        } catch (error) {
          data.receive_distributor_money = false
          Swal.fire({
            title: 'Ha ocurrido un error interno',
            text: '',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }


      }
    },
    async getReportMoneyInDistributor(moneyReceived = false) {
      try {
        this.loading = true

        const { data:res } = await ReportService.getReportMoneyInDistributor(this.rangePicker, moneyReceived)

        this.report = res.data

        this.loading = false

      } catch (error) {
        this.loading = false
      }
    },
    currencyFormat(amount) {
      return currencyFormat(amount)
    },
    dateFormat(date, format = "DD/MM/YYYY") {
      return moment(date).format(format);
    }
  },
  created() {
    this.getReportMoneyInDistributor()
  }
}
</script>
